<template>
    <div class="d-block d-lg-none">
  <div class="row">
    <div class="col-10 mb-0 col-sm-9 col-lg-10">
      <p class="mypracticeMobile" style="margin-left: 20px;">Inventory</p>
    </div>
    <div class="col-2 col-sm-3 col-lg-2">
        <div class="my-3 text-center">
    <button  class="btn btn-blue-color text-white" style="border-radius: 10px;" @click="GoSetting()" >Back</button>
  </div>
    </div>
  </div>
  <div class="hr-border" ></div>
</div>
<div class="px-4" v-if="!inventoryData.length">
  <div  style="margin-top: 15px;color:rgb(239, 63, 63)" >
      <h5>There are no itrms</h5>
      </div>
</div>
<div class="px-4" v-else>
<div class="row my-2">
      <div class="col-3">
        <label >Model number</label>
      </div>
      <div class="col-1">
      </div>
     
    </div>
    <div class="row mt-2" v-for="(value, index) in inventoryData" :key="index">
      
      <div class="col-3">
        <input type="text"  :disabled="!value.isEditable" :value="value.model_no"  autocomplete="off" class="form-control" />
      </div>
      
       <div class="col-1">
        <button type="button" @click="handleInputEditState(value, index)" style="font-size: 20px" id="btn_schedule_add"
          class="btn btn_edit">
          <font-awesome-icon :icon="['fas', 'edit']" />
        </button>
      </div>
      
    </div>
</div>
<div class="my-3 mx-3">
<button  class="btn btn-blue-color text-white" style="border-radius: 10px;" @click="enableClinic()" >Add new clinic</button>
</div>
<div class="mobileStyleDoctor" v-if="newModel">
  <div class="row clinicTextAlign" >
    <div class="clinic_section text-align text-left"  style="margin: 20px 0px">
        <fieldset class="curve-box" >
            <legend class="subHeadingText">Model info</legend> 
            <div class="row">
            <div class="col-12">
              <div class="form-group my-2">
                <label class="receiveConsultation">Model number<span class="star">*</span> </label>
                <input type="text" v-model="this.model_no" class="form-control" name="model_no" id="model_no"  
                  />
              </div>
            </div>
          </div>
          
          <div class="row my-2">
        <div class="col-5">
            <label>Category</label>
            <select class="form-control consultation-select" namxe="select" id="select" v-model="userSelected">
                <option value="Gents">Gents</option>
                <option value="Ladies">Ladies</option>
                <option value="Unisex">Unisex</option>
            </select>
        </div>
        </div>

  <div class="row">
      <label>Upload Images</label>
      <div class="col-6" v-for="n in 6" :key="n">
  <div class="form-group my-2">
    <input
      @change="uploadImage($event, n)"
      accept="image/*"
      placeholder="Upload Logo"
      type="file"
      class="form-control cursor UpdateTextAlignment"
      style="border-radius: 10px;"
    />
    <!-- <label v-if="imageFileNames[n - 1]" class="file-name">{{ imageFileNames[n - 1] }}</label> -->
  </div>
</div>     
     </div>
          <div class="row mt-3">
        <div class="col-8">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" name="active" id="active" v-model="isActive" />
            <label class="form-check-label " for="activateORdeactivate">Active</label>
          </div>
        </div>
      </div>
      <div class="row mt-3" v-if="imagesPreview.length">
        <div class="col-12">
          <label class="form-check-label " for="activateORdeactivate">Preview</label>
      <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-inner text-align text-center ">
      <div v-for="(image, index) in imagesPreview"  :key="index" :class="['carousel-item', { active: index === 0 }]">
          <img :src="image" class="img-carousel-public">
        </div>
    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
        </div>
      </div>
        </fieldset>
        <div class="clinicSubmitBtn my-3" >
    <div style="margin-right: 10px;">
        <button class="btn btn-blue-color text-white" @click="editbutton? updateModel(): createModel()">  {{ this.editbutton ? "Update" : "Submit" }}</button>
    </div>
    <div style="margin-left: 10px;">
         <button class="btn btn-color text-white " @click="GoSetting()">Cancel</button>
    </div>
    </div>
      </div>
    </div>
</div>
</template>
<script>
import axios from "axios";
export default {
props: ["docData"],
data() {
  return {
    images: [], // To store image file names
    imagesPreview: [],
    imageFileNames: [],
    updateModelData:null,
    inventoryData:[],
    newModel:false,
    model_no:'',
    isActive:false,
    coData: {},
    userSelected:'',
    editbutton: false,
    mobileValidation:/^[6-9]\d{9}$/,
  };
},
computed: {},
methods: {
    enableClinic(){
      this.editbutton = false; 
       this.newModel = true; 
       this.clearDatas();
    },
    clearDatas(){
this.model_no = '',
this.userSelected = ''
this.isActive = false,
this.images = [];
this.imagesPreview = [];
    },
  GoSetting() {
    window.scrollTo(0, 0);
    this.$router.push(`/clinicianothers/manage`);
  },
  uploadImage(event, index) {
   const file = event.target.files[0];
   if (file && file.type.startsWith('image/')) {
       const reader = new FileReader();
       reader.onload = (e) => {
           // Create a canvas element to manipulate the image
           const img = new Image();
           img.src = e.target.result;
           img.onload = () => {
               const canvas = document.createElement('canvas');
               const ctx = canvas.getContext('2d');
               
               // Set canvas size to the desired size for compression
               canvas.width = img.width; 
               canvas.height = img.height;
               
               // Draw the image on canvas
               ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
               
               // Get the compressed image data (70% quality in this case)
               const compressedData = canvas.toDataURL('image/jpeg', 0.7);

               // Update the preview with full resolution
               this.imagesPreview[index - 1] = e.target.result;
               this.imageFileNames[index - 1] = file.name;
               
               // Save the compressed data for uploading
               this.images[index - 1] = compressedData;
           };
       };
       reader.readAsDataURL(file);
   } else {
       this.$swal("Please select a valid image file!");
   }
},
handleInputEditState(value, index) {
  this.updateModelData = value;
  this.editbutton = true;
  this.newModel = true;
  this.model_no = value.model_no;
  this.userSelected = value.category;
  this.isActive = value.isActive;

  // Ensure imagesPreview is populated with full URLs
  if (value.images && value.images.length > 0) {
    this.images = value.images; // Store image names
    this.imagesPreview = value.images.map((imageName) => {
      return imageName;
    }); // Generate URLs for preview
  } else {
    this.images = [];
    this.imagesPreview = [];
  }

  let previousData = [...this.inventoryData];
  previousData[index].isEditable = true;
  this.inventoryData = previousData;
},
async createModel() {
    const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
        if (!this.model_no) {
            this.$swal("Please enter the Model number!");
            return
        }
        if (!this.userSelected) {
            this.$swal("Please select the Category!");
            return
        }

  let clinicPayload = {
        model_no: this.model_no,
        category: this.userSelected,
        admin_customer_id:this.coData.customer,
        isActive:this.isActive,
        images: this.images.filter(Boolean), // Only send non-empty values
      };
      await axios
        .post(`${BASE_API_URL}/foot-secure/inventory`, clinicPayload)
        .then((response) => {
            if(response.data){
              this.getInventory() ;
              this.clearDatas();
              this.$swal("Record is created");
              this.newModel=false
          }}
        )
        .catch((error) => {
          return error
          });
  },
  async updateModel(){
    if(this.updateModelData){
    const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
        if (!this.model_no) {
            this.$swal("Please enter the Model number!");
            return
        }
        if (!this.userSelected) {
            this.$swal("Please select the Category!");
            return
        }

  let clinicPayload = {
        model_no: this.model_no,
        category: this.userSelected,
        admin_customer_id:this.coData.customer,
        isActive:this.isActive,
        images: this.images.filter(Boolean),
        imageNames: this.imageFileNames
      };
      await axios
        .put(`${BASE_API_URL}/foot-secure/inventory/${this.updateModelData.id}/update`, clinicPayload)
        .then((response) => {
            if(response.data){
              this.getInventory() ;
              this.clearDatas();
              this.$swal("Record is updated");
              this.newModel=false
              this.imagesPreview = this.imagesPreview.filter(Boolean);
          }}
        )
        .catch((error) => {
          return error
          });
        }else{
          this.$swal("Cannot updata");
        }
  },
  validMobile(inputtxt){
            const mobilevalidation = /^[6-9]\d{9}$/;
            if(inputtxt.match(mobilevalidation)){
                return true
            }else{
                this.$swal("Please enter the correct mobile number!");
                return false;
            }
        },
    validEmail(inputtxt) {
        var email = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        var consecutiveSpecialChar = /[\W]{2}/;

        if (inputtxt.match(email) && !consecutiveSpecialChar.test(inputtxt)) {
            return true;
        } else {
            this.$swal("Please enter the correct email ID!");
            return false;
        }
    },
    getInventory() {
  const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
  axios
    .get(`${BASE_API_URL}/foot-secure/inventory`)
    .then((response) => {
      // Assuming the images are an array of strings (image names)
  
      this.inventoryData= response.data?.filter(item => item?.admin_customer_id?.id === this.coData?.customer?.id);
      // Now map over the filtered data and assign the image previews
      this.inventoryData.forEach(item => {
        if (item.images && item.images.length > 0) {
          item.imagesPreview = item.images.map(imageName => {
            return `${BASE_API_URL}/path/to/images/${imageName}`; // Adjust the path to match your API
          });
        } else {
          item.imagesPreview = [];
        }
      });
    })
    .catch((error) => {
      console.error("Error fetching inventory:", error);
    });
},
  async getClinicianOthersData() {
    const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
    await axios
      .get(`${BASE_API_URL}/clinician-others/${this.clinicnaOthersId}/one`)
      .then((response) => {
        this.coData = response.data
        this.coPincode = this.coData.pincode
      })
  },
},
created: async function () {
  let clinicianOthersID = localStorage.getItem('clinician-others-id');
   this.clinicnaOthersId = JSON.parse(clinicianOthersID);
   await this.getClinicianOthersData();
 await this.getInventory();
},
};
</script>
<style>
.carousel-control-next-icon, .carousel-control-prev-icon {
  background-color:#00979e;
  width: 5rem;
  height: 5rem;
}
.img-carousel-public {
  width: 300px;
  height:300px;
  object-fit: contain; /* Keep the image contained within the carousel, avoiding distortion */
  image-rendering: -webkit-optimize-contrast; /* Improve image rendering quality */
}
.clinicSubmitBtn{
    display: flex;
    justify-content:center;
}
.consultation-select{
/* width: 300px; */
font-size: 20px;
margin-left: auto;
margin-right: auto;
text-align: left;
margin-bottom: 5px;
-webkit-appearance: auto;
appearance:auto;
}
.clinicSubmitBtn{
    display: flex;
    justify-content:center;
}
 fieldset.curve-box {
    border: 2px solid #00979e;
    border-radius: 20px;
    padding: 15px;
    margin-top: 1.5rem;
    }
legend.subHeadingText {
padding: 4px 10px;
margin-bottom: 0px;
display: block;
float: none;
width: auto;
font-size: 20px;
}
.allfieldcontent{
color: red;
margin-top: 15px
}
.star {
  color: red;
  font-size: 20px;
}
.hideOpacity{
opacity: 0;
}
.yes-button,
.no-button {
padding: 10px 20px;
border: none;
border-radius: 4px;
cursor: pointer;
}

.yes-button {
background-color: #38761D;
color: #fff;
}

.no-button {
background-color: #FF9900;
color: #fff;
}
.popup {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background: rgba(0, 0, 0, 0.2) !important;
display: flex;
align-items: center;
justify-content: center;
}

.popup-content {
text-align: center;
background: #fff;
width: 400px;
padding: 20px;
border-radius: 8px;
box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}
.cross-icon{
font-size:25px;
color: #cd381c;
cursor: pointer;
float: right;
}
.clinicCreateBtn{
color: #00979e !important;
cursor: pointer;
}

button#btn_clinicconsult_add {
  border-color: transparent;
}

.disableCreateBtn{
color:  #dddddd !important;
pointer-events: none;
cursor: auto; 
}
.clinicTextAlign input{
text-align: left !important;;
}

/* .receiveConsultation {
color: #34989f;
}

.checkbox-inline {
color: #34989f;
} */
.checkbox-inline.heading {
font-size: 20px;
margin-bottom: 0px;
}

.my-3.subHeading {
/* color: #34989f; */
font-size: 18px;
}

.ms-5.text-color {
/* color: #34989f; */
font-size: 18px;
}
@media screen and (max-width:991px) {
.mobileStyleDoctor {
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 6rem;
}
.mypracticeMobile {
  margin-left: 20px;
  /* margin-bottom: 10px; */
  margin-top: 1rem;
  color: #000;
  font-weight: 550;
  font-size: 21px;
}
}
</style>
